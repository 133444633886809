.relative > .relative > .absolute {
    background: radial-gradient(circle at center, rgba(255, 255, 255, 0.25), transparent 70%);
  }
  
  .relative > .relative > .absolute:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  