.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: none;
  justify-content: center;
  align-items: center;
}

.loader-overlay.active {
  display: flex; /* Show loader when active */
  z-index: 100 !important;
}

.loader-content {
  text-align: center;
}

.loader-icon {
  font-size: 48px;
}

.loader-text {
  margin-top: 10px;
  font-size: 18px;
  color: white;
}
